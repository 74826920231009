import axios from "axios";
import { Loading } from "element-ui";
import qs from "qs";
import router from "../../router";
import Vue from "vue";
let showMessage = false;
/************************加载动画loading***************************/
window.loading = null;

function startLoading() {
  window.loading = Loading.service({
    lock: true, //是否锁定
    text: "" //加载中需要显示的文字
  });
}
//结束加载动画
function endLoading() {
  window.loading.close();
}

axios.interceptors.response.use(
  function(response) {
    endLoading(); //结束动画
    if (response.data.code == 600) {
      if (!showMessage) {
        showMessage = true;
        localStorage.clear();
        Vue.prototype.$message.error("请您先登录后再使用");
        router.push({ path: "/" });
        location.reload();
        // history.back();  //后退上一页
        // showMessage = false;
      }
      return;
    }
    return response.data;
  },
  function(error) {
    endLoading(); //结束动画
    return Promise.reject(error);
  }
);
const baseUrl =
  "https://5298363890752437.cn-beijing.fc.aliyuncs.com/2016-08-15/proxy"; //请求地址

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

// 变量
const dataService = "/data-service_debug";
const goods =
  process.env.VUE_APP_TITLE === "build"
    ? "/goods_debug"
    : "/goods_debug";
const user =
  process.env.VUE_APP_TITLE === "build"
    ? "/user_debug"
    : "/user_debug";

// const goods =
//   process.env.NODE_ENV === "development" ? "/goods_debug" : "/goods_release";
// const user =
//   process.env.NODE_ENV === "development" ? "/user_debug" : "/user_release";
// 传参
axios.interceptors.request.use(function(request) {
  startLoading();
  // console.log(request);
  if (request.data) {
    if (localStorage.getItem("userId")) {
      request.data.userId = localStorage.getItem("userId");
    }
    if (localStorage.getItem("token")) {
      request.data.token = localStorage.getItem("token");
    }
  }
  //token缓存设置 end
  var contentType = request.headers["Content-Type"];
  if (request.method === "post" && contentType != "application/json") {
    request.data = qs.stringify(request.data);
  }
  return request;
});
// pc端首页轮播图
export const lunbo = param => {
  return axios.post(
    baseUrl + dataService + "/data-service/appSysLunbo/list",
    param,
    {}
  );
};
// pc端首页获取课程相关信息
export const list = param => {
  return axios.post(baseUrl + goods + "/goods/sysGoods/list", param, {});
};
// 获取某一个课程的接口
export const selectOne = param => {
  return axios.post(baseUrl + goods + "/goods/sysGoods/info", param, {});
};
// 查询课程可以推荐的人
export const selectRecommendUser = param => {
  return axios.post(
    baseUrl + goods + "/goods/appUserCourse/selectRecommendUser",
    param,
    {}
  );
};
// 用户购买课程(未支付阶段)
export const insert = param => {
  return axios.post(baseUrl + goods + "/goods/appUserCourse/insert", param, {});
};
// 用户注册
export const register = param => {
  return axios.post(baseUrl + user + "/user/api/register", param, {});
};
// 获取验证码
export const getCode = param => {
  return axios.post(baseUrl + user + "/code/api/getCode", param, {});
};
// 给推荐人发送验证码
export const getCodeCourse = param => {
  return axios.post(
    baseUrl + goods + "/goods/appUserCourse/getCode",
    param,
    {}
  );
};
// 用户登录接口
export const login = param => {
  return axios.post(baseUrl + user + "/login/api/login", param, {});
};
// 重置密码
export const restPassword = param => {
  return axios.post(baseUrl + user + "/user/api/restPassword", param, {});
};
// 获取一个用户的用户信息
export const userselectOne = param => {
  return axios.post(baseUrl + user + "/user/api/selectOne", param, {});
};
// 获取用户的账户金额信息
export const moneyselectOne = param => {
  return axios.post(
    baseUrl + goods + "/goods/appUserMoney/selectOne",
    param,
    {}
  );
};
// 获取一个用户的交易记录
export const billselectOne = param => {
  return axios.post(
    baseUrl + goods + "/goods/appUserMoneyLog/selectOne",
    param,
    {}
  );
};
// 绑定邮箱,修改绑定邮箱,绑定手机,修改绑定手机
export const toEmailMobile = param => {
  return axios.post(baseUrl + user + "/user/api/toEmailMobile", param, {});
};
// 验证验证码是否正确
export const checkCode = param => {
  return axios.post(baseUrl + user + "/code/api/checkCode", param, {});
};
// 取用户的支付账户信息(包含是否可以提现)
export const cashselectOne = param => {
  return axios.post(
    baseUrl + goods + "/goods/appUserCash/selectOne",
    param,
    {}
  );
};
// 用户开始提现
export const toCash = param => {
  return axios.post(baseUrl + goods + "/goods/appUserCash/toCash", param, {});
};
// 用户点击记住银行卡,支付宝信息时.
export const cashinsert = param => {
  return axios.post(baseUrl + goods + "/goods/appUserCash/insert", param, {});
};
// 用户点击取消记住的时候,调用此方法,删除用户的支付宝,paypal信息
export const cashdelete = param => {
  return axios.post(baseUrl + goods + "/goods/appUserCash/delete", param, {});
};
// 用户上传头像图片
export const imgupload = param => {
  return axios.post(baseUrl + user + "/user/api/imgupload", param, {});
};
// 修改用户的头像路径
export const updateUrl = param => {
  return axios.post(baseUrl + user + "/user/api/updateUrl", param, {});
};
// 查询用户提现的总金额
export const cashTotalPrice = param => {
  return axios.post(
    baseUrl + goods + "/goods/appUserCash/cashTotalPrice",
    param,
    {}
  );
};
// 获取售后政策的内容
export const Salelist = param => {
  return axios.post(
    baseUrl + dataService + "/data-service/appSysAfterSale/list",
    param,
    {}
  );
};
// 获取解决方案的内容
export const Solutlist = param => {
  return axios.post(
    baseUrl + dataService + "/data-service/appSysSolution/list",
    param,
    {}
  );
};
// 获取关于我们的内容
export const Aboutlist = param => {
  return axios.post(
    baseUrl + dataService + "/data-service/appSysAboutUs/list",
    param,
    {}
  );
};
// 获取客户合作的内容
export const Customerlist = param => {
  return axios.post(
    baseUrl + dataService + "/data-service/appSysCustomerCooperation/list",
    param,
    {}
  );
};
// 查询产品服务的内容
export const Serviceslist = param => {
  return axios.post(
    baseUrl + dataService + "/data-service/appSysProductServices/list",
    param,
    {}
  );
};
// 支付宝支付完成的时候调用此方法
export const alipayPay = param => {
  return axios.post(baseUrl + goods + "/goods/stripeMy/alipayPay", param, {});
};
// 微信支付完成时候,调用此方法
export const wechatPay = param => {
  return axios.post(baseUrl + goods + "/goods/stripeMy/wechatPay", param, {});
};
// 用户通过paypel购买课程
export const pay = param => {
  return axios.post(baseUrl + goods + "/goods/paypelMy/pay", param, {});
};
// 使用国际银行信用卡或者国际银行卡的时候,用户输完信息以后,需要调用此方法.
export const cardPay = param => {
  return axios.post(baseUrl + goods + "/goods/stripeMy/cardPay", param, {});
};
// 修改用户名
export const updateUsername = param => {
  return axios.post(baseUrl + user + "/user/api/updateUsername", param, {});
};
// vip用户购买课程
export const insertVIP = param => {
  return axios.post(
    baseUrl + goods + "/goods/appUserCourse/insertVIP",
    param,
    {}
  );
};
//论坛主题列表
export const getThemeList = param => {
  return axios.post(baseUrl + goods + "/forum/themeInfo/list", param, {});
};
//获取帖子列表
export const getStickList = param => {
  return axios.post(baseUrl + goods + "/forum/stickInfo/list", param, {});
};
//获取帖子详情
export const getStickInfo = param => {
  return axios.post(baseUrl + goods + "/forum/stickInfo/info", param, {});
};
//发布帖子
export const saveStickInfo = param => {
  return axios.post(baseUrl + goods + "/forum/stickInfo/save", param, {});
};
//获取帖子回复
export const getReplyInfo = param => {
  return axios.post(baseUrl + goods + "/forum/replyInfo/list", param, {});
};
//发布回复
export const saveReplyInfo = param => {
  return axios.post(baseUrl + goods + "/forum/replyInfo/save", param, {});
};

<template>
  <div id="app">
    <VHeader />
    <router-view />
  </div>
</template>
<script>
import VHeader from "@/components/V-Header.vue";
export default {
  components: {
    VHeader
  }
};
</script>
<style lang="scss" scoped>
#app {
  height: 100%;
}
</style>

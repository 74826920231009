import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import "./assets/css/common.css";
import axios from "axios";
import $ from "jquery";
// IE
import "babel-polyfill";
Vue.prototype.$axios = axios;
//全局变量图片地址前缀
Vue.prototype.imgBasUrl = "https://47.89.39.53:8163";
// Vue.prototype.imgBasUrl = "https://www.expenglish.org:8163";
// 支付宝
Vue.prototype.alipayUrl = "https://aaea0953.ngrok.io/mydemo/stripeMy/callBack";
Vue.prototype.isProduction = process.env.VUE_APP_TITLE === "build";
Vue.config.productionTip = false;

new Vue({
  $,
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  }
}).$mount("#app");

import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/coursedetails/:id",
      name: "coursedetails",
      component: () =>
        import(
          /* webpackChunkName: "coursedetails" */ "./views/CourseDetails.vue"
        )
    },
    {
      path: "/buynow",
      name: "buynow",
      component: () =>
        import(/* webpackChunkName: "buynow" */ "./views/BuyNow.vue")
    },
    {
      path: "/students",
      name: "students",
      component: () =>
        import(/* webpackChunkName: "students" */ "./views/Students.vue")
    },
    {
      path: "/settlement",
      name: "settlement",
      component: () =>
        import(/* webpackChunkName: "settlement" */ "./views/Settlement.vue")
    },
    {
      path: "/usermain",
      name: "usermain",
      component: () =>
        import(/* webpackChunkName: "usermain" */ "./views/UserMain.vue")
    },
    {
      path: "/changeemail",
      name: "changeemail",
      component: () =>
        import(/* webpackChunkName: "changeemail" */ "./views/ChangeEmail.vue")
    },
    {
      path: "/changephone",
      name: "changephone",
      component: () =>
        import(/* webpackChunkName: "changephone" */ "./views/ChangePhone.vue")
    },
    {
      path: "/withdrawal",
      name: "withdrawal",
      component: () =>
        import(/* webpackChunkName: "withdrawal" */ "./views/Withdrawal.vue")
    },
    {
      path: "/about",
      name: "about",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue")
    },
    {
      path: "/exithead",
      name: "exithead",
      component: () =>
        import(/* webpackChunkName: "exithead" */ "./views/ExitHead.vue")
    },
    {
      path: "/service",
      name: "service",
      component: () =>
        import(/* webpackChunkName: "service" */ "./views/Service.vue")
    },
    {
      path: "/forum/:id",
      name: "forum",
      component: () =>
        import(/* webpackChunkName: "forum" */ "./views/Forum.vue")
    },
    {
      path: "/forum/detail/:themeId/:id",
      name: "ForumDetail",
      component: () =>
        import(/* webpackChunkName: "ForumDetail" */ "./views/ForumDetail.vue")
    },
    {
      path: "/stick/post",
      name: "stickPost",
      component: () =>
        import(/* webpackChunkName: "stickPost" */ "./views/ForumSubmit.vue")
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

<template>
  <header>
    <div class="container">
      <router-link to="/" class="logo"></router-link>
      <h1>Exponential English</h1>
      <div class="loginbtn" v-if="!isLoginShow">
        <a class="login-btn" @click="headMask('login')">登录</a> |
        <a class="register-btn" @click="headMask('register')">注册</a>
      </div>
      <!--登录成功-->
      <div class="user-name" v-if="isLoginShow">
        <el-dropdown>
          <span class="el-dropdown-link">
            <div class="headportrait">
              <img :src="ImgUrl" v-if="ImgUrl" />
              <img src="../assets/images/moren.png" alt v-else />
              <!-- @click="exitImg" -->
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <router-link to="/usermain">
              <el-dropdown-item style="line-height:30px;">个人中心</el-dropdown-item>
            </router-link>
            <a @click="editPassword">
              <el-dropdown-item divided style="line-height:30px;">修改密码</el-dropdown-item>
            </a>
            <a @click="exitClick">
              <el-dropdown-item divided style="line-height:30px;">退出</el-dropdown-item>
            </a>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!--end-->
    </div>
    <!-- 登录 -->
    <el-dialog
      title="登录"
      :visible.sync="dialogLogin"
      :lock-scroll="false"
      :close-on-click-modal="false"
      class="loginmain"
    >
      <div class="popupmain">
        <dl>
          <dt>手机号/邮箱</dt>
          <dd>
            <input type="text" placeholder="请输入手机号或邮箱" v-model="value" />
          </dd>
        </dl>
        <dl>
          <dt>密码</dt>
          <dd>
            <input type="password" placeholder="请输入密码" v-model="password" />
          </dd>
        </dl>
        <button @click="loginClick" v-loading.fullscreen.lock="fullscreenLoading">确定</button>
        <!-- <a @click="dialogForget = true" class="forgetpassword">忘记密码</a> -->
        <a @click="headMask('forget')" class="forgetpassword">忘记密码</a>
      </div>
    </el-dialog>
    <!-- 注册 -->
    <el-dialog
      title="注册"
      :visible.sync="dialogRegister"
      :lock-scroll="true"
      :close-on-click-modal="false"
      class="loginmain"
    >
      <el-tabs v-model="activeName" class="logintabclick">
        <el-tab-pane label="手机号" name="first">
          <div class="popupmain">
            <dl>
              <dt>昵称</dt>
              <dd>
                <input type="text" placeholder="请输入昵称" v-model="username" />
              </dd>
            </dl>
            <dl>
              <dt>手机号</dt>
              <dd>
                <input
                  type="text"
                  placeholder="请输入手机号"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                  v-model="value"
                />
              </dd>
            </dl>
          </div>
        </el-tab-pane>
        <el-tab-pane label="邮箱" name="second">
          <div class="popupmain">
            <dl>
              <dt>昵称</dt>
              <dd>
                <input type="text" placeholder="请输入昵称" v-model="username" />
              </dd>
            </dl>
            <dl>
              <dt>邮箱</dt>
              <dd>
                <input type="text" placeholder="请输入邮箱" v-model="value" />
              </dd>
            </dl>
          </div>
        </el-tab-pane>
        <div class="popupmain">
          <dl>
            <dt>验证码</dt>
            <dd>
              <input type="text" placeholder="请输入验证码" style="width:70%;" v-model="code" />
              <a
                @click="sendmessage(1)"
                :class="{active:time>0}"
                v-loading.fullscreen.lock="fullscreenLoading"
              >{{sendMessageName}}</a>
            </dd>
          </dl>
          <dl>
            <dt>密码</dt>
            <dd>
              <input type="password" placeholder="请输入密码(6-20数字字母组合)" v-model="password" />
            </dd>
          </dl>
          <dl>
            <dt>确认密码</dt>
            <dd>
              <input type="password" placeholder="请再次输入密码" v-model="getpassword" />
            </dd>
          </dl>
          <button @click="registerClick" v-loading.fullscreen.lock="fullscreenLoading">确定</button>
        </div>
      </el-tabs>
    </el-dialog>
    <!-- 加载中 -->
    <div class="loading" v-if="loadshow">
      <div>
        <img src="../assets/images/loading.gif" alt />
      </div>
    </div>
    <!-- 忘记密码 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogForget"
      :lock-scroll="false"
      :close-on-click-modal="false"
      class="loginmain"
    >
      <el-tabs v-model="activeName" class="logintabclick">
        <el-tab-pane label="手机号" name="first">
          <div class="popupmain">
            <dl>
              <dt>手机号</dt>
              <dd>
                <input
                  type="text"
                  placeholder="请输入手机号"
                  :readonly="readonly"
                  oninput="this.mobile = this.value.replace(/[^0-9]/g, '')"
                  v-model="mobile"
                />
              </dd>
            </dl>
          </div>
        </el-tab-pane>
        <el-tab-pane label="邮箱" name="second">
          <div class="popupmain">
            <dl>
              <dt>邮箱</dt>
              <dd>
                <input
                  type="text"
                  :readonly="readonly"
                  autocomplete="off"
                  placeholder="请输入邮箱"
                  v-model="email"
                />
              </dd>
            </dl>
          </div>
        </el-tab-pane>
        <div class="popupmain">
          <dl>
            <dt>验证码</dt>
            <dd>
              <input
                type="text"
                name="code"
                autocomplete="off"
                placeholder="请输入验证码"
                style="width:70%;"
                v-model="code"
              />
              <a
                @click="sendmessage(2)"
                :class="{active:time>0}"
                v-loading.fullscreen.lock="fullscreenLoading"
              >{{sendMessageName}}</a>
            </dd>
          </dl>
          <dl>
            <dt>密码</dt>
            <dd>
              <input
                type="password"
                autocomplete="off"
                placeholder="请输入密码(6-20数字字母组合)"
                v-model="password"
              />
            </dd>
          </dl>
          <dl>
            <dt>确认密码</dt>
            <dd>
              <input type="password" placeholder="请再次输入密码" v-model="getpassword" />
            </dd>
          </dl>
          <button @click="getpasswordClick">确定</button>
        </div>
      </el-tabs>
    </el-dialog>
  </header>
</template>
<style>
.loading {
  width: 100%;
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.loading div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  /* Firefox */
  -webkit-transform: translateX(-50%) translateY(-50%);
  /* Safari 和 Chrome */
  -ms-transform: translateX(-50%) translateY(-50%);
  /* Internet Explorer */
  -o-transform: translateX(-50%) translateY(-50%);
  background: rgba(255, 255, 255, 1);
  width: 200px;
  height: 200px;
  line-height: 200px;
  opacity: 0.5;
  border-radius: 5px;
}
</style>
<script>
// 用户注册,获取验证码,用户登录,重置密码
import {
  register,
  getCode,
  login,
  restPassword,
  userselectOne
} from "@/assets/js/api";
export default {
  name: "v-header",
  data: () => {
    return {
      readonly: false,
      fullscreenLoading: false,
      isLoginShow: false,
      dialogLogin: false,
      dialogRegister: false,
      dialogForget: false,
      activeName: "first",
      value: "",
      mobile: "",
      email: "",
      code: "",
      password: "",
      getpassword: "",
      sendMessageName: "发送验证码",
      time: 0,
      ImgUrl: "",
      loadshow: false,
      username: "",
      title: "忘记密码"
    };
  },
  methods: {
    // 登录
    headMask: function(name) {
      if (name == "login") {
        this.dialogLogin = true;
      } else if (name == "register") {
        this.dialogRegister = true;
      } else if (name == "forget") {
        this.dialogForget = true;
      }
      clearInterval(this.timer);
      this.readonly = false;
      this.time = 0;
      this.sendMessageName = "发送验证码";
      this.mobile = "";
      this.email = "";
      this.value = "";
      this.code = "";
      this.password = "";
      this.getpassword = "";
    },
    exitImg() {
      this.$router.push("/exithead");
    },
    // 清空token,userId
    exitClick: function() {
      window.localStorage.clear();
      this.$message({
        message: "退出登录成功",
        type: "success"
      });
      this.isLoginShow = false;
      this.$router.push("/");
    },
    editPassword() {
      this.readonly = true;
      this.mobile = "";
      this.email = "";
      let userInfo = localStorage.getItem("userInfo");
      if (userInfo) {
        this.userInfo = JSON.parse(userInfo);
      }
      if (this.activeName == "first") {
        this.mobile = this.userInfo.mobile;
      } else {
        this.email = this.userInfo.email;
      }
      this.title = "修改密码";
      this.dialogForget = true;
    },
    // 倒计时60s
    get() {
      this.time--;
      if (this.time == 0) {
        clearInterval(this.timer);
        this.sendMessageName = "重新发送";
        return;
      }
      this.sendMessageName = this.time + "s重新发送";
    },
    sendmessage(type) {
      if (type == 2) {
        this.value = this.activeName == "first" ? this.mobile : this.email;
      }
      if (this.value == "") {
        this.$message.error("请检查您的手机号或者邮箱是否书写正确");
        return;
      }
      if (this.time == 0) {
        this.fullscreenLoading = true;
        getCode({
          value: this.value,
          type: type
        }).then(result => {
          if (result.code == 0) {
            setTimeout(() => {
              this.fullscreenLoading = false;
            }, 500);
            this.time = 120;
            this.timer = setInterval(this.get, 1000);
            this.$message({
              message: "验证码发送成功，请查收短信",
              type: "success"
            });
          } else {
            setTimeout(() => {
              this.fullscreenLoading = false;
            }, 500);
            this.$message.error(result.msg);
            return;
          }
        });
      }
    },
    // 销毁之前调用
    beforeDestroy() {
      clearInterval(this.timer);
    },
    // 注册
    registerClick() {
      var vReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/; // 不少于6位 必须有数字和字母 不超过20位
      if (this.value == "") {
        this.$message.error("请检查您的手机号或者邮箱是否书写正确");
        return;
      } else if (this.code == "") {
        this.$message.error("验证码不能为空");
        return;
      } else if (this.password == "") {
        this.$message.error("密码不能为空");
        return;
      } else if (!vReg.test(this.password)) {
        this.$message.error("密码不少于6位 必须有数字和字母 不超过20位");
        return;
      } else if (this.getpassword == "") {
        this.$message.error("确认密码不能为空");
        return;
      } else if (this.password != this.getpassword) {
        this.$message.error("两次密码输入不一致");
        return;
      }
      if (this.username == "") {
        this.$message.error("用户昵称不能为空");
        return;
      }
      register({
        value: this.value,
        code: this.code,
        password: this.password,
        username: this.username
      }).then(result => {
        if (result.code == 0) {
          this.$message({
            message: "恭喜你，注册成功",
            type: "success"
          });
          this.dialogRegister = false;
          this.dialogLogin = true;
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    // 登录
    loginClick: function() {
      login({
        value: this.value,
        state: 1,
        password: this.password
      }).then(result => {
        if (result.code == 0) {
          this.isLoginShow = true;
          this.dialogLogin = false;
          this.$message({
            message: "登录成功",
            type: "success"
          });
          this.ImgUrl = result.user.url;
          localStorage.setItem("userInfo", JSON.stringify(result.user));
          localStorage.setItem("userId", result.user.userId);
          localStorage.setItem("token", result.token);
          if (this.$route.paht != "/") {
            location.reload();
          }
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    // 重置密码
    getpasswordClick: function() {
      var vReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/; // 不少于6位 必须有数字和字母 不超过20位
      this.value = this.activeName == "first" ? this.mobile : this.email;
      if (this.value == "") {
        this.$message.error("请检查您的手机号或者邮箱是否书写正确");
        return;
      } else if (this.code == "") {
        this.$message.error("验证码不能为空");
        return;
      } else if (this.password == "") {
        this.$message.error("密码不能为空");
        return;
      } else if (!vReg.test(this.password)) {
        this.$message.error("密码不少于6位 必须有数字和字母 不超过20位");
        return;
      } else if (this.getpassword == "") {
        this.$message.error("确认密码不能为空");
        return;
      } else if (this.password != this.getpassword) {
        this.$message.error("两次密码输入不一致");
        return;
      }
      restPassword({
        value: this.value,
        code: this.code,
        password: this.password
      }).then(result => {
        if (result.code == 0) {
          this.$message({
            message: "密码修改成功，请重新登录",
            type: "success"
          });
          this.dialogForget = false;
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    // 用户信息
    userselectOne() {
      userselectOne({}).then(result => {
        if (result.code == 0) {
          this.ImgUrl = result.user.url;
        }
      });
    }
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.userselectOne();
    }
  },
  created() {
    if (
      localStorage.getItem("token") != null &&
      localStorage.getItem("userId") != null
    ) {
      this.isLoginShow = true;
    } else {
      this.isLoginShow = false;
    }
  }
};
</script>
